<template>
  <div>
    <div class="main-Pc-wholeImg">
      <div class="nav-box">
        <div class="nav">
          <img class="Pc-back" :src="Pc_back" alt="" @click="pageHandle('/home')">
          <div class="" @click="select()" style="cursor: pointer;">
            <el-image :src="Pc_select" style="width: 20px;line-height: 17px;margin-top: 4px;" lazy />
          </div>
          <ul class="nav-language" v-if="flag">
            <!-- <li><a href="/tcapp-download-b/ja">日本語</a></li> -->
            <li><a href="/tcapp-download-b/sc">简体中文</a></li>
            <li><a href="/tcapp-download-b/tc">繁体中文</a></li>
            <li><a href="/tcapp-download-b/en">English</a></li>
            <li><a href="/tcapp-download-b/kr">한국어</a></li>
          </ul>
        </div>
      </div>
      <div class="btnBoxZh" v-show="['zh'].includes(language)">
        <div class="Pc-link-box">
          <a class="Pc-apple-link" href="https://apps.apple.com/tw/app/travel-contents-%E6%97%85%E9%81%8A%E6%8E%A7/id1597706553" target="_blank" rel="noopener noreferrer">
            <img :src="appleDownlaodBtn" alt="">
          </a>
          <!-- <a class="Pc-google-link" href="/tcappNo108V183.apk" target="_blank" rel="noopener noreferrer">
            <img :src="androidDownlaodBtn" alt="">
          </a> -->
        </div>
      </div>
      <!-- en -->
      <div class="btnBoxEN" v-show="['en','ja'].includes(language)">
        <div class="Pc-link-boxEN">
          <a class="Pc-apple-linkEN" href="https://www.youtube.com/watch?v=1bQYiySAAmg" target="_blank" rel="noopener noreferrer">
            <img :src="moreDownlaodBtn" alt="">
          </a>
          <a class="Pc-apple-linkEN" href="https://apps.apple.com/tw/app/travel-contents-%E6%97%85%E9%81%8A%E6%8E%A7/id1597706553" target="_blank" rel="noopener noreferrer">
            <img :src="appleDownlaodBtn" alt="">
          </a>
          <a class="Pc-google-linkEN" href="https://play.google.com/store/apps/details?id=com.tripellet.app" target="_blank" rel="noopener noreferrer">
            <img :src="googleDownlaodBtn" alt="">
          </a>
          <img :src="QR_DL" alt="" class="QR_DL">
        </div>
      </div>
      <!-- tw/kr -->
      <div class="btnBoxTW" v-show="['zh_tw'].includes(language)">
        <div class="Pc-link-boxKR">
          <a class="Pc-apple-linkKR" href="https://apps.apple.com/tw/app/travel-contents-%E6%97%85%E9%81%8A%E6%8E%A7/id1597706553" target="_blank" rel="noopener noreferrer">
            <img :src="appleDownlaodBtn" alt="">
          </a>
          <a class="Pc-google-linkKR" href="https://play.google.com/store/apps/details?id=com.tripellet.app" target="_blank" rel="noopener noreferrer">
            <img :src="googleDownlaodBtn" alt="">
          </a>
          <img :src="QR_DL" alt="" class="QR_DL">
        </div>
      </div>
      <div class="btnBoxKR" v-show="['ko'].includes(language)">
        <div class="Pc-link-boxKR">
          <a class="Pc-apple-linkKR" href="https://apps.apple.com/tw/app/travel-contents-%E6%97%85%E9%81%8A%E6%8E%A7/id1597706553" target="_blank" rel="noopener noreferrer">
            <img :src="appleDownlaodBtn" alt="">
          </a>
          <!-- <a class="Pc-google-linkKR" href="" target="_blank" rel="noopener noreferrer">
            <img :src="googleDownlaodBtn" alt="">
          </a> -->
          <img :src="QR_DL" alt="" class="QR_DL">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reloadMessage } from "@/lang";
import { downloadPageB_list_PC } from "@/utils/public_data";
export default {
  data() {
    return {
      contentList: downloadPageB_list_PC(),
      appleDownlaodBtn: require("@/assets/images/downloadPage/B/button/PC_apple.png"),
      androidDownlaodBtn: require("@/assets/images/downloadPage/B/button/PC_android.png"),
      googleDownlaodBtn: require("@/assets/images/downloadPage/B/button/PC_google.png"),
      moreDownlaodBtn: require("@/assets/images/downloadPage/B/button/PC_more.png"),
      QR_DL: require("@/assets/images/downloadPage/B/button/QR_DL.png"),
      Pc_back: require("@/assets/images/customer/PC-home.png"),
      Pc_select: require("@/assets/images/customer/PC_line.png"),
      flag: false,
      lan: "",
      showBtn: false,
    };
  },
  created() {
    if (localStorage.getItem("locale") === "zh") {
      this.lan = "简体中文";
    } else if (localStorage.getItem("locale") === "zh_tw") {
      this.lan = "繁体中文";
    } else if (localStorage.getItem("locale") === "ko") {
      this.lan = "한국어";
    } else if (localStorage.getItem("locale") === "en") {
      this.lan = "English";
    }
  },
  computed: {
    language() {
      return localStorage.getItem("locale") || "zh_tw";
    },
  },
  methods: {
    select() {
      this.flag = !this.flag;
    },
    pageHandle(path, params) {
      if (params) {
        this.$router.push({ path, query: { search: JSON.stringify(params) } });
      } else {
        this.$router.push(path);
      }
    },
    changLan(val) {
      // 设置语言
      this.$i18n.locale = val;
      localStorage.setItem("locale", val);
      reloadMessage();
      this.$router.go(0);
    },
    background() {
      this.flag = false;
    },
    imgOnload() {
      this.showBtn = true;
      this.$forceUpdate();
    },
  },
};
</script>

<style lang='scss' scoped>
.app-container {
  width: 100%;
  margin: 0 auto;
  padding: 0;
  border: 0;
}

.main-Pc-wholeImg {
  max-width: 100vw;
  position: relative;
}

.Pc-back {
  width: 30px;
  float: left;
  left: 3px;
  top: 2px;
  height: 27.84px !important;
}
.Pc-selectBox {
  float: right;
}
.Pc-select {
  width: 20px;
  height: 15px;
  float: right;
  cursor: pointer;
}
.nav-box {
  width: 100%;
  height: 40px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}
.nav {
  width: 1000px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nav-language {
  position: absolute;
  right: 24%;
  top: 40px;
  margin: 5px 0;
  background-color: #fff;
  border: 1px solid #ebeef5;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 #0000001a;
  z-index: 999 !important;
}
.nav-language li {
  list-style: none;
  line-height: 36px;
  font-size: 14px;
  cursor: pointer;
  outline: 0;
  border-bottom: 1px solid #0000001a;
}
.nav-language li a {
  color: #606266;
  display: block;
  width: 96px;
  text-align: center;
}
.nav-language li a:hover {
  background-color: #ecf5ff;
  color: #66b1ff;
}
.nav-language li:hover {
  background-color: #ecf5ff;
  color: #66b1ff;
}
.rr {
  position: relative;
  height: 20px;
  width: 12px;
  top: -12px;
  left: 7px;
  background: #0c0c0c;
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.btnBoxZh::-webkit-scrollbar {
  width: 0;
  overflow-x: hidden;
  overflow-y: hidden;
}
.btnBoxZh {
  min-height: 95.8vh;
  margin: 0px;
  background: url("../../assets/images/downloadPage/B/CH_A.jpg") no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: 50%;
  position: relative;
  margin-top: 40px;
  .Pc-link-box {
    position: fixed;
    bottom: 17.2% !important;
    right: 30%;
    z-index: 999;
    .Pc-apple-link img {
      width: 170px;
      margin-right: 1rem;
      margin-bottom: 8px;
    }
    .Pc-google-link img {
      width: 150px;
      margin-bottom: 8px;
    }
    .QR_DL {
      width: 62px;
      margin-left: 1rem;
    }
  }
}
.btnBoxKR {
  min-height: 95.8vh;
  margin: 0px;
  background: url("../../assets/images/downloadPage/B/KR_A.jpg") no-repeat;
  background-size: contain;
  background-attachment: fixed;
  background-position: 50%;
  position: relative;
  margin-top: 40px;
  .Pc-link-boxKR {
    position: fixed;
    bottom: 13.2% !important;
    right: 35%;
    .Pc-apple-linkKR img {
      width: 150px;
      margin-right: 1rem;
      margin-bottom: 8px;
    }
    .Pc-google-linkKR img {
      width: 150px;
      margin-bottom: 8px;
    }
    .QR_DL {
      width: 62px;
      margin-left: 1rem;
    }
  }
}
.btnBoxTW {
  min-height: 95.8vh;
  margin: 0px;
  background: url("../../assets/images/downloadPage/B/TW_A.png") no-repeat;
  background-size: contain;
  background-attachment: fixed;
  background-position: 50%;
  position: relative;
  margin-top: 40px;
  .Pc-link-boxKR {
    position: fixed;
    bottom: 13.2% !important;
    right: 35%;
    .Pc-apple-linkKR img {
      width: 150px;
      margin-right: 1rem;
      margin-bottom: 8px;
    }
    .Pc-google-linkKR img {
      width: 150px;
      margin-bottom: 8px;
    }
    .QR_DL {
      width: 62px;
      margin-left: 1rem;
    }
  }
}
.btnBoxEN {
  min-height: 95.8vh;
  margin: 0px;
  background: url("../../assets/images/downloadPage/B/EN_A.png") no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: 50%;
  position: relative;
  margin-top: 40px;
  .Pc-link-boxEN {
    position: fixed;
    bottom: 16.2% !important;
    right: 24%;
    z-index: 999;
    .Pc-apple-linkEN img {
      width: 150px;
      margin-right: 1rem;
      margin-bottom: 8px;
    }
    .Pc-google-linkEN img {
      width: 150px;
      margin-bottom: 8px;
    }
    .QR_DL {
      width: 62px;
      margin-left: 1rem;
    }
  }
}
@media screen and (min-width: 769px) and(max-width: 1023.9px) {
  .btnBoxZh {
    min-height: 95.8vh;
    margin: 0px;
    background: url("../../assets/images/downloadPage/B/CH_A.jpg") no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: 50%;
    position: relative;
    margin-top: 40px;
    .Pc-link-box {
      position: fixed;
      bottom: 17.2% !important;
      right: 20vw;
      z-index: 999;
      .Pc-apple-link img {
        width: 170px;
        margin-right: 1rem;
        margin-bottom: 8px;
      }
      .Pc-google-link img {
        width: 150px;
        margin-bottom: 8px;
      }
    }
  }
  .btnBoxKR {
    min-height: 95.8vh;
    margin: 0px;
    background: url("../../assets/images/downloadPage/B/KR_A.jpg") no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: 50%;
    position: relative;
    margin-top: 40px;
    .Pc-link-boxKR {
      position: fixed;
      bottom: 17.2% !important;
      right: 14%;
      .Pc-apple-linkKR img {
        width: 150px;
        margin-right: 1rem;
        margin-bottom: 8px;
      }
      .Pc-google-linkKR img {
        width: 150px;
        margin-bottom: 8px;
      }
      .QR_DL {
        width: 62px;
        margin-left: 1rem;
      }
    }
  }
  .btnBoxTW {
    min-height: 95.8vh;
    margin: 0px;
    background: url("../../assets/images/downloadPage/B/TW_A.png") no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: 50%;
    position: relative;
    margin-top: 40px;
    .Pc-link-boxKR {
      position: fixed;
      bottom: 17.2% !important;
      right: 14%;
      .Pc-apple-linkKR img {
        width: 150px;
        margin-right: 1rem;
        margin-bottom: 8px;
      }
      .Pc-google-linkKR img {
        width: 150px;
        margin-bottom: 8px;
      }
      .QR_DL {
        width: 62px;
        margin-left: 1rem;
      }
    }
  }
  .btnBoxEN {
    min-height: 95.8vh;
    margin: 0px;
    background: url("../../assets/images/downloadPage/B/EN_A.png") no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: 50%;
    position: relative;
    margin-top: 40px;
    .Pc-link-boxEN {
      position: fixed;
      bottom: 16.2% !important;
      right: 10vw;
      z-index: 999;
      .Pc-apple-linkEN img {
        width: 150px;
        margin-right: 1rem;
        margin-bottom: 8px;
      }
      .Pc-google-linkEN img {
        width: 150px;
        margin-bottom: 8px;
      }
      .QR_DL {
        width: 62px;
        margin-left: 1rem;
      }
    }
  }
}
@media screen and (min-width: 1024px) and (max-width: 1440px) {
  .btnBoxZh {
    min-height: 95.8vh;
    margin: 0px;
    background: url("../../assets/images/downloadPage/B/CH_A.jpg") no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: 50%;
    position: relative;
    margin-top: 40px;
    .Pc-link-box {
      position: fixed;
      bottom: 17.2% !important;
      right: 28vw;
      z-index: 999;
      .Pc-apple-link img {
        width: 170px;
        margin-right: 1rem;
        margin-bottom: 8px;
      }
      .Pc-google-link img {
        width: 150px;
        margin-bottom: 8px;
      }
    }
  }
  .btnBoxKR {
    min-height: 95.8vh;
    margin: 0px;
    background: url("../../assets/images/downloadPage/B/KR_A.jpg") no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: 50%;
    position: relative;
    margin-top: 40px;
    .Pc-link-boxKR {
      position: fixed;
      bottom: 17.2% !important;
      right: 22%;
      .Pc-apple-linkKR img {
        width: 150px;
        margin-right: 1rem;
        margin-bottom: 8px;
      }
      .Pc-google-linkKR img {
        width: 150px;
        margin-bottom: 8px;
      }
      .QR_DL {
        width: 62px;
        margin-left: 1rem;
      }
    }
  }
  .btnBoxTW {
    min-height: 95.8vh;
    margin: 0px;
    background: url("../../assets/images/downloadPage/B/TW_A.png") no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: 50%;
    position: relative;
    margin-top: 40px;
    .Pc-link-boxKR {
      position: fixed;
      bottom: 17.2% !important;
      right: 22%;
      .Pc-apple-linkKR img {
        width: 150px;
        margin-right: 1rem;
        margin-bottom: 8px;
      }
      .Pc-google-linkKR img {
        width: 150px;
        margin-bottom: 8px;
      }
      .QR_DL {
        width: 62px;
        margin-left: 1rem;
      }
    }
  }
  .btnBoxEN {
    min-height: 95.8vh;
    margin: 0px;
    background: url("../../assets/images/downloadPage/B/EN_A.png") no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: 50%;
    position: relative;
    margin-top: 40px;
    .Pc-link-boxEN {
      position: fixed;
      bottom: 16.2% !important;
      right: 19vw;
      z-index: 999;
      .Pc-apple-linkEN img {
        width: 150px;
        margin-right: 1rem;
        margin-bottom: 8px;
      }
      .Pc-google-linkEN img {
        width: 150px;
        margin-bottom: 8px;
      }
      .QR_DL {
        width: 62px;
        margin-left: 1rem;
      }
    }
  }
}
@media screen and (min-width: 1440.9px) and (max-width: 2560px) {
  .btnBoxKR {
    min-height: 95.8vh;
    margin: 0px;
    background: url("../../assets/images/downloadPage/B/KR_A.jpg") no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: 50%;
    position: relative;
    margin-top: 40px;
    .Pc-link-boxKR {
      position: fixed;
      bottom: 13.2% !important;
      right: 29%;
      .Pc-apple-linkKR img {
        width: 150px;
        margin-right: 1rem;
        margin-bottom: 8px;
      }
      .Pc-google-linkKR img {
        width: 150px;
        margin-bottom: 8px;
      }
      .QR_DL {
        width: 62px;
        margin-left: 1rem;
      }
    }
  }
  .btnBoxTW {
    min-height: 95.8vh;
    margin: 0px;
    background: url("../../assets/images/downloadPage/B/TW_A.png") no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: 50%;
    position: relative;
    margin-top: 40px;
    .Pc-link-boxKR {
      position: fixed;
      bottom: 13.2% !important;
      right: 29%;
      .Pc-apple-linkKR img {
        width: 150px;
        margin-right: 1rem;
        margin-bottom: 8px;
      }
      .Pc-google-linkKR img {
        width: 150px;
        margin-bottom: 8px;
      }
      .QR_DL {
        width: 62px;
        margin-left: 1rem;
      }
    }
  }
  .btnBoxZh {
    min-height: 95.8vh;
    margin: 0px;
    background: url("../../assets/images/downloadPage/B/CH_A.jpg") no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: 50%;
    position: relative;
    margin-top: 40px;
    .Pc-link-box {
      position: fixed;
      bottom: 17.2% !important;
      right: 33%;
      z-index: 999;
      .Pc-apple-link img {
        width: 170px;
        margin-right: 1rem;
        margin-bottom: 8px;
      }
      .Pc-google-link img {
        width: 150px;
        margin-bottom: 8px;
      }
    }
  }
}
</style>